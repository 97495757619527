@font-face {
  font-family: 'RobotoMono';
  src: url('./fonts/RobotoMono-Regular.ttf')  format('truetype')
}

body {
  font-family: 'RobotoMono';
  background: #3C3C3C;
}

.uk-section-default {
  background: #3C3C3C;
}

.uk-subnav>*>a:hover {
  color: #C9CD18!important;
}