#site-head {
  width: 100%;
  height: 80px;
  min-height: 80px;
  background-color: #222222;
}

.logo {
  a {
    font-size: 20px!important;
    color: #FFFFFF!important;
    font-family: 'RobotoMono' !important;
    text-transform: none;
  }
}

.logo a {
  span {
    text-transform: none;
  }
}

.uk-subnav a {
  font-size: 18px;
  &.active {
    color: #C9CD18;
  }
}

.custom-menu-item {
  padding: 20px;
}
