.uk-category-btn {
  background: #333333;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.25) !important;
  border-radius: 2px;
  color: #fff;
  margin-right: 10px;
  border: none !important;
  text-transform: none;
  padding: 5px 21px;
  margin-top: 10px;

  &:hover {
    background: #333;
    color: #C9CD18;
  }

  &:focus {
    color: #fff;
  }

  &.active{
    background: #333;
    color: #C9CD18;
  }
}

.lang-list {
  margin-top: 25px;
  margin-left: -20px;
}